<template>
    <div class="profile full-height full-width">
        <template v-if="loading">
            <v-container fluid class="pa-6 full-height full-width">
                <div class="full-height full-width d-flex align-center justify-center">
                    <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                </div>
            </v-container>
        </template>

        <template v-else>
            <div class="d-flex align-start full-width full-height overflow-hidden" :class="$vuetify.breakpoint.mdAndUp ? 'flex-row' : 'flex-column'">
                <div class="flex-shrink-0 pa-6" :class="{ 'full-width': $vuetify.breakpoint.smAndDown }">
                    <h3 :class="$vuetify.breakpoint.mdAndUp ? 'mb-6' : 'mb-3'">Profile</h3>

                    <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-card class="pa-3 elevation-0 light full-width" width="250">
                            <v-list dense nav class="py-0">
                                <v-list-item :disabled="item.disabled" link color="primary" v-for="(item, index) in menu" :key="index" :to="{ name: item.name, path: item.path }" class="navigation-inactive my-2" active-class="navigation-active">
                                    <v-list-item-icon>
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            ><b>{{ item.title }}</b></v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </template>

                    <template v-else>
                        <v-select v-model="$route" :items="menu" class="field full-width" dense filled solo flat item-text="title" item-value="path" hide-details></v-select>
                    </template>
                </div>

                <div class="flex-shrink-1 full-width full-height overflow-hidden">
                    <transition name="slide-y-reverse-transition" mode="out-in" appear>
                        <router-view />
                    </transition>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
// Services
// import ServiceName from '@/services/service-name';

// Components
// import ComponentName from '@/components/component-name';

// Packages
// import _ from 'lodash';
// import moment from 'moment';

export default {
    name: "profile",

    meta: {
        title: "Profile",
    },

    props: {},

    components: {},

    data() {
        return {
            loading: false,
            menu: [
                {
                    title: "Settings",
                    name: "profile.settings",
                    icon: "mdi-cog-outline",
                    path: "/profile/settings",
                },
                // {
                //     title: "Security",
                //     name: "profile.security",
                //     icon: "mdi-lock-outline",
                //     path: "/profile/security",
                // }
            ],
        };
    },

    computed: {},

    methods: {},

    async mounted() {},
};
</script>

<style lang="scss">
.new-template {
}
</style>
